import useMutation from './use-mutation';
import useFileMutation from './use-mutation/fileMutation';
import useQuery from './use-query';
import useFileApi from './use-query/fileQuery';

const route = process.env.REACT_APP_API_URL;

const routes = {
	publicImages: `${route}/public-images`,
	signIn: `${route}/signin`,
	accessToken: `${route}/user`,
	refreshToken: `${route}/refresh`,
	users: `${route}/users`,
	client: `${route}/register/client`,
	patchClient: `${route}/clients`,
	getClients: `${route}/clients`,
	getClientsBySalesAgent: `${route}/sales-agent/search-client`,
	getClientsList: `${route}/clients/all`,
	salesAgents: `${route}/users/get-sales-agents`,
	getUsersByName: `${route}/users/get-by-name`,
	warehouses: `${route}/warehouse`,
	file: `${route}/media`,
	products: `${route}/product`,
	searchProducts: `${route}/product/getProductBySearchText`,
	searchProductsByModerator: `${route}/product-moderator/search-product`,
	getProductInfoForModerator: `${route}/product-moderator/info-product`,
	salesAgentSearchProducts: `${route}/sales-agent/search-product`,
	salesAgentCheckProductCount: `${route}/sales-agent/check-product-count`,
	dillerProducts: `${route}/diller-products`,
	postUser: `${route}/register/adminUsers`,
	currency: `${route}/currency`,
	expenditureCategory: `${route}/expenditure-category`,
	expenditures: `${route}/expenditure`,
	categories: `${route}/category`,
	categoriesCascader: `${route}/category/for-select`,
	imports: `${route}/import`,
	importProducts: `${route}/import-product`,
	priceHistory: `${route}/price-history`,
	partners: `${route}/partner`,
	promocode: `${route}/partner/promocode`,
	orders: `${route}/order`, // this is temporary
	createOrdersBySalesAgent: `${route}/sales-agent/create-order`,
	createOrdersByDiller: `${route}/sales-agent/create-order-from-diller`,
	orderClose: `${route}/order/order-close`,
	standartDiscounts: `${route}/standart-discounts`,
	dynamicDiscounts: `${route}/dynamyic-discount`,
	dynamicDiscountsCalculate: `${route}/dynamyic-discount/calculate`,
	generalSettings: `${route}/general-setting`,
	orderRequests: `${route}/order-request`,
	orderRequest: `${route}/order-request`,
	orderRequestProduct: `${route}/order-product`,
	orderProductForProductModerator: `${route}/order/order-product-for-product-moderator`,
	validateOrderProduct: `${route}/order-product/validate`,
	orderProductDonorDiller: `${route}/order-product/donor-dillers`,
	changeUserInformation: `${route}/users/profile`,
	changeUserPassword: `${route}/reset-password`,
	returnProduct: `${route}/returned-product`,
	serviceCenter: `${route}/service-center`,
	changeUserPasswordByAdmin: `${route}/reset-password-by-admin`,
	changeBalance: `${route}/users/update-balance`,
	changeBalanceCashier: `${route}/users/update-cashier-balance`,
	statistics: `${route}/statistics`,
	cashierBalanceHistory: `${route}/users/cashier-balance-history`,
	getDealerBalanceByCategory: `${route}/users/get-diller-debts`,
	orderProduct: `${route}/order-product`,
	dillerBalanceRecord: `${route}/users/diller-balance-record`,
	orderForDillerVitrina: `${route}/order/order-for-diller-vitrina`,
	dillerTransfers: `${route}/diller-transfers`,
	getDillerTransfers: `${route}/diller-transfers`,
	setDillerTransferStatus: `${route}/diller-transfers`,
	incomeType: `${route}/income-type`,
	adminReportGetters: `${route}/admin-reports-getters`,
	reports: `${route}/reports`,
	reportsShareFromSale: `${route}/reports/share-from-sale`
};

export const GetShareFromSale = params => {
	return useQuery(routes.reportsShareFromSale, params);
};

// RETURN PRODUCTS
export const GetReturnProducts = (params, options) => {
	return useQuery(routes.returnProduct, params, { cacheTime: 0 });
};
export const PatchReturnProduct = id => {
	return useMutation('patch', `${routes.returnProduct}/${id}`);
};
export const PostReturnProduct = () => {
	return useMutation('post', routes.returnProduct);
};
export const GetReturnProductsExcell = () => {
	return useMutation('post', `${routes.returnProduct}/excell`);
};
export const GetReturnProductExcell = () => {
	return useMutation('post', routes.returnProduct);
};
export const GetReturnProductSingle = (params, options) => {
	return useQuery(`${routes.returnProduct}/get-one`, params, { cacheTime: 0, ...options });
};

// USERS
export const GetUsers = params => {
	return useQuery(routes.users, params);
};

export const GetUser = (id, params = {}, options = {}) => {
	return useQuery(`${routes.users}/${id}`, params, options);
};

export const PostUser = () => {
	return useMutation('post', routes.postUser);
};

export const PatchUser = id => {
	return useMutation('patch', `${routes.users}/${id}`);
};

export const DeleteUsers = () => {
	return useMutation('delete', `${routes.users}`);
};

export const DeleteUser = id => {
	return useMutation('delete', `${routes.users}/${id}`);
};

export const GetSalesAgents = params => {
	return useQuery(routes.salesAgents, params);
};

export const GetUsersByName = (params, options = {}) => {
	return useQuery(routes.getUsersByName, params, options);
};
// USER(CLIENT)
export const GetClientUsers = params => {
	return useQuery(routes.getClientsList, params);
};

export const SearchClient = (params = {}, options = {}) => {
	return useQuery(`${routes.getClients}`, params, options);
};

export const SearchClientBySales = (params = {}, options = {}) => {
	return useQuery(`${routes.getClientsBySalesAgent}`, params, options);
};

export const GetClientUser = (id, params = {}, options = {}) => {
	return useQuery(`${routes.client}/${id}`, params, options);
};

export const PostClientUser = () => {
	return useMutation('post', routes.client);
};

export const PatchClientUser = id => {
	return useMutation('patch', `${routes.patchClient}/${id}`);
};

export const DeleteClientUsers = () => {
	return useMutation('delete', `${routes.client}`);
};

export const DeleteClientUser = id => {
	return useMutation('delete', `${routes.client}/${id}`);
};

// DILLER BALANCE RECORD
export const GetDillerBalanceRecord = (id, params = {}, options = {}) => {
	return useQuery(`${routes.dillerBalanceRecord}/${id}`, params, options);
};

// ORDER REQUEST
export const GetOrderRequests = (params = {}, options = {}) => {
	return useQuery(routes.orderRequests, params, options);
};

// ORDER FOR DILLER VITRINA
export const PostOrderForDillerVitrina = () => {
	return useMutation('post', routes.orderForDillerVitrina);
};

export const PatchOrderRequest = id => {
	return useMutation('patch', `${routes.orderRequests}/${id}`);
};

// ORDER PRODUCT DONOR DILLER
export const GetOrderProductDonorDiller = (id, params = {}, options = {}) => {
	return useQuery(`${routes.orderProductDonorDiller}/${id}`, params, options);
};

// TRANSFER-DILLER
export const TransferDiller = () => {
	return useMutation('post', routes.dillerTransfers);
};

export const GetTransferDillerData = (params = {}, options = {}) => {
	return useQuery(routes.getDillerTransfers, params, options);
};
export const GetTransferDillerDataToExcell = () => {
	return useMutation('post', `${routes.getDillerTransfers}/excell`);
};
export const GetTransferDillerDataByGroup = (params = {}, options = {}) => {
	return useQuery(`${routes.getDillerTransfers}/byGroupId`, params, options);
};
export const GetTransferInfo = (params = {}, options = {}) => {
	return useQuery(`${routes.getDillerTransfers}/transfer-info`, params, options);
};

export const SetDillerTransfersStatus = id => {
	return useMutation('patch', `${routes.setDillerTransferStatus}/${id}`);
};
// ORDER PRODUCT BY ORDER
export const GetOrderProduct = (params = {}, options = {}) => {
	return useQuery(routes.orderProduct, params, options);
};

// ORDER PRODUCT
export const GetOrderProducts = (params = {}, options = {}) => {
	return useQuery(routes.orderProductForProductModerator, params, options);
};

export const PatchOrderProducts = id => {
	return useMutation('patch', `${routes.orderRequestProduct}/${id}`);
};
export const DeleteOrderProducts = () => {
	return useMutation('delete', `${routes.orderRequestProduct}`);
};
export const UpdateProducts = () => {
	return useMutation('patch', `${routes.orderRequestProduct}/edit`);
};
export const GetOrderProductsExcellForWarehouse = () => {
	return useMutation('post', `${routes.orderProduct}/order-products-excell-for-warehouse`);
};

// ORDER REQUEST
export const GetOrderRequest = (id, params = {}, options = {}) => {
	return useQuery(`${routes.orderRequest}/${id}`, params, options);
};

// ORDER REQUEST PRODUCT
export const GetOrderRequestProduct = (params = {}, options = {}) => {
	return useQuery(routes.orderRequestProduct, params, options);
};
// Service Center
export const GetServiceCenter = params => {
	return useQuery(routes.serviceCenter, params);
};
export const PostServiceCenter = () => {
	return useMutation('post', routes.serviceCenter);
};
export const GetSingleServiceCenter = (id, params = {}, options = {}) => {
	return useQuery(`${routes.serviceCenter}/${id}`, params, options);
};
export const PatchServiceCenter = id => {
	return useMutation('patch', `${routes.serviceCenter}/${id}`);
};
// WAREHOUSE
export const GetWarehouses = params => {
	return useQuery(routes.warehouses, params);
};
export const GetFile = (path, params = {}) => {
	return useFileApi(`${routes.file}/file/${path}`, params, { enabled: false });
};
export const GetWarehouse = (id, params = {}, options = {}) => {
	return useQuery(`${routes.warehouses}/${id}`, params, options);
};
export const PostFile = () => {
	return useMutation('post', routes.file);
};
export const PostWarehouse = () => {
	return useMutation('post', routes.warehouses);
};
export const PostGenerateDoc = () => {
	return useFileMutation('post', `${routes.warehouses}/import-to-excel`);
};

export const PatchWarehouse = id => {
	return useMutation('patch', `${routes.warehouses}/${id}`);
};

export const DeleteWarehouses = () => {
	return useMutation('delete', `${routes.warehouses}`);
};

export const DeleteWarehouse = id => {
	return useMutation('delete', `${routes.warehouses}/${id}`);
};

// PROMOCODE

export const GetPromocode = (promocode, params = {}, options = {}) => {
	return useQuery(`${routes.promocode}/${promocode}`, params, options);
};

// PRICE HISTORY
export const GetPriceHistory = params => {
	return useQuery(routes.priceHistory, params);
};

// CASHIER BALANCE HISTORY
export const GetCashierBalanceHistory = (id, params = {}, options = {}) => {
	return useQuery(`${routes.cashierBalanceHistory}/${id}`, params, options);
};

// PRODUCTS
export const GetProducts = (params = {}, options = {}) => {
	return useQuery(routes.products, params, options);
};
// export const GetProductsAnaliticsForModerator = (params = {}, options = {}) => {
// 	return useQuery(`${routes.products}/analitics-by-search`, params, options);
// };//Previous method
export const GetProductsAnaliticsForModerator = (params = {}, options = {}) => {
	// New method
	return useQuery(`${routes.getProductInfoForModerator}`, params, options);
};
export const GetProductsListToExcell = (params = {}) => {
	return useQuery(`${routes.products}/excell`, params, { enabled: false });
};

export const SearchProducts = (params, options = {}) => {
	return useQuery(routes.searchProducts, params, options);
};

export const SearchProductsByModerator = (params, options = {}) => {
	return useQuery(routes.searchProductsByModerator, params, options);
};

export const SalesAgentSearchProducts = (params, options = {}) => {
	return useQuery(routes.salesAgentSearchProducts, params, options);
};

export const SalesAgentCheckProductCount = (params, options = {}) => {
	return useQuery(routes.salesAgentCheckProductCount, params, options);
};

export const GetDillerProducts = (params = {}, options = {}) => {
	return useQuery(routes.dillerProducts, params, options);
};
export const GetDillerProductsToExcell = (params = {}, options = {}) => {
	return useQuery(`${routes.dillerProducts}/excell`, params, options);
};

export const GetExcellOfDillerProducts = (params = {}, options = {}) => {
	return useFileApi(`${routes.dillerProducts}/import-to-excel`, params, { enabled: false });
};
export const GetProduct = (id, params = {}, options = {}) => {
	return useQuery(`${routes.products}/${id}`, params, options);
};

export const PostProduct = () => {
	return useMutation('post', routes.products);
};

export const PatchProduct = id => {
	return useMutation('patch', `${routes.products}/${id}`);
};

export const DeleteProducts = () => {
	return useMutation('delete', `${routes.products}`);
};

export const DeleteProduct = id => {
	return useMutation('delete', `${routes.products}/${id}`);
};
export const GetProductReportToExcellQuery = params => {
	return useQuery(`${routes.products}/report/download`, params, { enabled: false });
};

// STANDART DISCOUNT
export const GetStandartDiscounts = params => {
	return useQuery(routes.standartDiscounts, params);
};

export const GetStandartDiscount = (id, params = {}, options = {}) => {
	return useQuery(`${routes.standartDiscounts}/${id}`, params, options);
};

export const PostStandartDiscount = () => {
	return useMutation('post', routes.standartDiscounts);
};

export const PatchStandartDiscount = id => {
	return useMutation('patch', `${routes.standartDiscounts}/${id}`);
};

export const DeleteStandartDiscounts = () => {
	return useMutation('delete', `${routes.standartDiscounts}`);
};

export const DeleteStandartDiscount = id => {
	return useMutation('delete', `${routes.standartDiscounts}/${id}`);
};

// DYNAMIC DISCOUNT
export const GetDynamicDiscounts = params => {
	return useQuery(routes.dynamicDiscounts, params);
};

export const GetDynamicDiscount = (id, params = {}, options = {}) => {
	return useQuery(`${routes.dynamicDiscounts}/${id}`, params, options);
};

export const PostDynamicDiscount = () => {
	return useMutation('post', routes.dynamicDiscounts);
};

export const CalclulateDynamicDiscount = () => {
	return useMutation('post', routes.dynamicDiscountsCalculate);
};

export const PatchDynamicDiscount = id => {
	return useMutation('patch', `${routes.dynamicDiscounts}/${id}`);
};

export const DeleteDynamicDiscounts = () => {
	return useMutation('delete', `${routes.dynamicDiscounts}`);
};

export const DeleteDynamicDiscount = id => {
	return useMutation('delete', `${routes.dynamicDiscounts}/${id}`);
};

// CURRENCY
export const GetCurrencies = params => {
	return useQuery(routes.currency, params);
};

export const PostCurrency = () => {
	return useMutation('post', routes.currency);
};

export const PatchCurrency = id => {
	return useMutation('patch', `${routes.currency}/${id}`);
};

export const DeleteCurrency = id => {
	return useMutation('delete', `${routes.currency}/${id}`);
};

// EXPENDITURE-CATEGORIES
export const GetExpenditureCategories = params => {
	return useQuery(routes.expenditureCategory, params);
};

export const PostExpenditureCategory = () => {
	return useMutation('post', routes.expenditureCategory);
};

export const PatchExpenditureCategory = id => {
	return useMutation('patch', `${routes.expenditureCategory}/${id}`);
};

export const DeleteExpenditureCategories = () => {
	return useMutation('delete', `${routes.expenditureCategory}`);
};

// Expenditures
export const GetExpenditures = params => {
	return useQuery(routes.expenditures, params);
};

export const PostExpenditure = () => {
	return useMutation('post', routes.expenditures);
};

export const PatchExpenditure = id => {
	return useMutation('patch', `${routes.expenditures}/${id}`);
};

export const DeleteExpenditures = () => {
	return useMutation('delete', `${routes.expenditures}`);
};

export const DeleteExpenditure = id => {
	return useMutation('delete', `${routes.expenditures}/${id}`);
};

// IMPORTS
export const GetImports = params => {
	return useQuery(routes.imports, params);
};

export const PostImport = () => {
	return useMutation('post', routes.imports);
};

export const PatchImport = id => {
	return useMutation('patch', `${routes.imports}/${id}`);
};

// IMPORT-PRODUCTS
export const GetImportProducts = params => {
	return useQuery(routes.importProducts, params);
};

export const PostImportProducts = () => {
	return useMutation('post', routes.importProducts);
};

export const DeleteImportProduct = id => {
	return useMutation('delete', `${routes.importProducts}/${id}`);
};

// CATEGORIES
export const GetCategories = params => {
	return useQuery(routes.categories, params);
};

export const GetCategoriesCascader = params => {
	return useQuery(routes.categoriesCascader, params, { cacheTime: 5000 });
};

export const GetCategory = (id, params = {}, options = {}) => {
	return useQuery(`${routes.categories}/${id}`, params, options);
};

export const PostCategory = () => {
	return useMutation('post', routes.categories);
};

export const PatchCategory = id => {
	return useMutation('patch', `${routes.categories}/${id}`);
};

export const DeleteCategories = () => {
	return useMutation('delete', `${routes.categories}`);
};

export const DeleteCategory = id => {
	return useMutation('delete', `${routes.categories}/${id}`);
};

// Partners
export const GetPartners = params => {
	return useQuery(routes.partners, params);
};

export const PostPartner = () => {
	return useMutation('post', routes.partners);
};

export const PatchPartner = id => {
	return useMutation('patch', `${routes.partners}/${id}`);
};

export const DeletePartner = id => {
	return useMutation('delete', `${routes.partners}/${id}`);
};

// ORDER
export const GetOrders = (params, options = {}) => {
	return useQuery(routes.orders, params, options);
};
export const GetDealerDebtByCategory = (params, options = {}) => {
	return useQuery(routes.getDealerBalanceByCategory, params, options);
};

export const PostOrder = () => {
	return useMutation('post', routes.orders);
};

export const PostOrderBySales = () => {
	return useMutation('post', routes.createOrdersBySalesAgent);
};

export const PostOrderByDiller = () => {
	return useMutation('post', routes.createOrdersByDiller);
};

export const UpdateOrder = () => {
	return useMutation('patch', routes.orders);
};

export const GetDillerSelfOrdersToExcell = () => {
	return useMutation('post', `${routes.orders}/diller-self-orders-excell`);
};
export const GetDillerAgentsOrdersToExcell = () => {
	return useMutation('post', `${routes.orders}/diller-agent-orders-excell`);
};

export const PostOrderByModeratorFromExcel = () => {
	return useMutation('post', `${routes.orders}/order-for-diller-from-excel`);
};

export const DeleteOrder = id => {
	return useMutation('delete', `${routes.orders}/${id}`);
};
export const GetSingleOrder = id => {
	return useQuery(`${routes.orders}/${id}`, { enabled: !!id });
};

export const CloseOrder = id => {
	return useMutation('patch', `${routes.orderClose}/${id}`);
};
export const PostPrintOrder = () => {
	return useMutation('post', `${routes.orders}/print-order/`);
};

// GENERAL SETTINGS

export const GetGeneralSettings = params => {
	return useQuery(routes.generalSettings, params);
};

export const PostGeneralSettings = () => {
	return useMutation('post', routes.generalSettings);
};

export const PatchGeneralSetting = id => {
	return useMutation('patch', `${routes.generalSettings}/${id}`);
};

export const DeleteGeneralSetting = id => {
	return useMutation('delete', `${routes.generalSettings}/${id}`);
};

// ORDER REQUEST PRODUCTS
export const GetOrderRequestProducts = params => {
	return useQuery(routes.orderRequestProduct, params);
};
export const GetOrderRequestProductsToPdf = () => {
	return useMutation('post', `${routes.orderRequestProduct}/pdf`);
};

export const ValidateOrderProduct = () => {
	return useMutation('post', routes.validateOrderProduct);
};

export const PatchOrderProduct = id => {
	return useMutation('patch', `${routes.orderRequestProduct}/${id}`);
};

// CHANGE USER INFORMATION

export const ChangeUserInformation = id => {
	return useMutation('patch', `${routes.changeUserInformation}/${id}`);
};
export const ChangeUserPassword = () => {
	return useMutation('post', routes.changeUserPassword);
};

// CHANGE USER PASSWORD BY ADMIN
export const ChangeUserInformationByAdmin = () => {
	return useMutation('post', `${routes.changeUserPasswordByAdmin}`);
};

// CHANGE BALANCE
export const ChangeBalance = id => {
	return useMutation('patch', `${routes.changeBalance}/${id}`);
};

export const ChangeBalanceCashier = id => {
	return useMutation('patch', `${routes.changeBalanceCashier}/${id}`);
};

// STATISTICS
export const GetStatisticsTopSellingProducts = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topSellingProducts`, params, options);
};

export const GetStatisticsTopLowSellingProducts = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topLowSellingProducts`, params, options);
};

export const GetStatisticsTopDillers = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topDillers`, params, options);
};

export const GetStatisticsTopPartners = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topPartners`, params, options);
};

export const GetStatisticsTopSalesAgents = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topSalesAgents`, params, options);
};

export const GetStatisticsTopProductModeratorProducts = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topProductModeratorProducts`, params, options);
};

export const GetStatisticsTopProductModeratorLowSaleProducts = (params = {}, options = {}) => {
	return useQuery(`${routes.statistics}/topProductModeratorLowSaleProducts`, params, options);
};
export const CancelOrder = () => {
	return useMutation('post', `${routes.orders}/order-cancel`);
};
// IncomeType
export const GetIncomeType = params => {
	return useQuery(routes.incomeType, params);
};

export const PostIncomeType = () => {
	return useMutation('post', routes.incomeType);
};
export const ResortIncomeType = () => {
	return useMutation('post', `${routes.incomeType}/resort`);
};

export const PatchIncomeType = id => {
	return useMutation('patch', `${routes.incomeType}/${id}`);
};

export const DeleteIncomeType = id => {
	return useMutation('delete', `${routes.incomeType}/${id}`);
};

export const GetAdminReportGetters = params => {
	return useQuery(routes.adminReportGetters, params);
};
export const PostAdminReportGetters = () => {
	return useMutation('post', routes.adminReportGetters);
};

export const PatchAdminReportGetters = id => {
	return useMutation('patch', `${routes.adminReportGetters}/${id}`);
};

export const DeleteAdminReportGetters = id => {
	return useMutation('delete', `${routes.adminReportGetters}/${id}`);
};

// reports
export const PostCreateAdminReport = () => {
	return useMutation('post', `${routes.reports}/report-admin`);
};
export const GetAdminReportFileLog = (params = {}, options = {}) => {
	return useQuery(`${routes.reports}/admin-log`, params, options);
};
export const GetShareFromSaleReportExcell = () => {
	return useMutation('post', `${routes.reports}/share-from-sale/excell`);
};

// close all orders
export const CloseAllPendingOrders = () => {
	return useQuery(`${routes.orders}/close-all-pending`, {}, { enabled: false });
};
export default routes;
